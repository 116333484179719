import React, { useEffect, useState, Suspense, lazy } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loginActions } from './store/login-slice';
import Spinner from '../app/shared/Spinner';
import { matchPath } from 'react-router';


const Dashboard = lazy(() => import('./dashboard/Dashboard'));
const HistoryData = lazy(() => import('./dashboard/HistoryData'));



const Activity = lazy(() => import('./pages/Activity'));
const Ehs = lazy(() => import('./pages/Ehs'));
const Gms = lazy(() => import('./pages/Gms'));
const Location = lazy(() => import('./pages/Location'));
const User = lazy(() => import('./pages/User'));
const AppUser = lazy(() => import('./pages/AppUser'));
const Eptw = lazy(() => import('./pages/Eptw'));
const Dropdown = lazy(() => import('./pages/Dropdown'));
const Incident = lazy(() => import('./pages/Incident'));
const IncidentReview = lazy(() => import('./pages/IncidentReview'));
const Inspection = lazy(() => import('./pages/Inspection'));
const Audit = lazy(() => import('./pages/Audit'));
const Plant = lazy(() => import('./pages/Plant'));
const Equipment = lazy(() => import('./asset/Dashboard'));
const Login = lazy(() => import('./user-pages/Login'));
const Logout = lazy(() => import('./pages/Logout'));
const ActionList = lazy(() => import('./pages/ActionList'));
const Reports = lazy(() => import('./pages/Reports'));
const BugReport = lazy(() => import('./pages/BugReport'));
const UserList = lazy(() => import('./pages/UserList'));
// const AppUser = lazy(() => import('./pages/AppUser'));
const MasterUser = lazy(() => import('./pages/MasterUser'));
const PublicObs = lazy(() => import('./pages/PublicObs'));

const AppRoutes = () => {
  const isLogin = useSelector((state) => state.login.isLogin);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    // Async function to validate token (if you fetch from server or have any async ops)
    const validateToken = async () => {
      // Simulating an API call
      return new Promise((resolve) => {
        setTimeout(() => {
          const hasValidToken = Boolean(localStorage.getItem('access_token'));
          resolve(hasValidToken);
        }, 1000); // Fake delay
      });
    };

    const checkLoginStatus = async () => {
      const hasValidToken = await validateToken();
      if (hasValidToken) {
        dispatch(loginActions.setLogin());
      } else {
        dispatch(loginActions.setLogout());
      }
      setIsLoading(false);
    };

    checkLoginStatus();
  }, [dispatch]);

  if (isLoading) {
    return <Spinner />;
  }

 
  return (
    <Suspense fallback={<Spinner />}>
      <Switch>


        <Route exact path="/">
          {isLogin ? <Redirect to="/home" /> : <Redirect to="/login" />}
        </Route>

        <Route path="/login">
          {isLogin ? <Redirect to="/home" /> : <Login />}
        </Route>

        <Route path="/publicObs/:id/:mode">
          <PublicObs />
        </Route>

        <Route path="/dashboard">
          {isLogin ? <ActionList /> : <Redirect to="/login" />}
        </Route>

        <Route exact path="/logout" >
          <Logout />
        </Route>

        <Route exact path="/home" >
          {isLogin ? <HistoryData /> : <Redirect to="/login" />}
        </Route>

        <Route exact path="/bug_report" >
          {isLogin ? <BugReport /> : <Redirect to="/login" />}
        </Route>

        <Route exact path="/apps/ehs" >
          {isLogin ? <Ehs /> : <Redirect to="/login" />}
        </Route>

        {/* <Route exact path="/dashboard" >
          {isLogin ? <ActionList /> : <Redirect to="/login" />}
        </Route> */}

        <Route exact path="/gms" >
          {isLogin ? <Gms /> : <Redirect to="/login" />}
        </Route>

        <Route exact path="/location" >
          {isLogin ? <Location /> : <Redirect to="/login" />}
        </Route>

        <Route exact path="/dropdown" >
          {isLogin ? <Dropdown /> : <Redirect to="/login" />}
        </Route>


        <Route exact path="/app-user" >
          {isLogin ? <AppUser /> : <Redirect to="/login" />}
        </Route>

        <Route exact path="/activity" >
          {isLogin ? <Activity /> : <Redirect to="/login" />}
        </Route>

        <Route exact path="/apps/eptw" >
          {isLogin ? <Eptw /> : <Redirect to="/login" />}
        </Route>

        <Route exact path="/apps/incident" >
          {isLogin ? <Incident /> : <Redirect to="/login" />}
        </Route>

        <Route exact path="/apps/inspection" >
          {isLogin ? <Inspection /> : <Redirect to="/login" />}
        </Route>

        <Route exact path="/apps/audit" >
          {isLogin ? <Audit /> : <Redirect to="/login" />}
        </Route>

        <Route exact path="/plant" >
          {isLogin ? <Plant /> : <Redirect to="/login" />}
        </Route>
        <Route exact path="/apps/equipment" >
          {isLogin ? <Equipment /> : <Redirect to="/login" />}
        </Route>

        <Route exact path="/incident-review" >
          {isLogin ? <IncidentReview /> : <Redirect to="/login" />}
        </Route>

        <Route exact path="/apps/reports" >
          {isLogin ? <Reports /> : <Redirect to="/login" />}
        </Route>
        <Route exact path="/users/user" >
          {isLogin ? <UserList /> : <Redirect to="/login" />}
        </Route>

        <Route exact path="/users/app-user" >
          {isLogin ? <AppUser /> : <Redirect to="/login" />}
        </Route>

        <Route exact path="/users/master-user" >
          {isLogin ? <MasterUser /> : <Redirect to="/login" />}
        </Route>







        <Redirect to="/dashboard" />
      </Switch>
    </Suspense>
  );

}

export default AppRoutes;