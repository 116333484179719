// InfoIconWithTooltip.js
import React from 'react';
import { Tooltip } from 'primereact/tooltip';

const InfoIconWithTooltip = ({ id, data }) => {
    return (
        <>
            <i className="pi pi-info-circle" style={{ fontSize: '1.5em', cursor: 'pointer' }} id={id}></i>
            <Tooltip target={`#${id}`} content={data} position="right" mouseTrack mouseTrackTop={10} />
        </>
    );
};

export default InfoIconWithTooltip;
