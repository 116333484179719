import React, { useState, useEffect } from "react";
import API from "../services/API";
import { LOCATION1_URL, LOCATION_TIER1_URL, TIER1_TIER2_URL, TIER2_TIER3_URL, DYNAMIC_TITLES_URL } from "../constants";


const FilterLocation = (props) => {

    const [locationOne, setLocationOne] = useState([]);
    useEffect(() => {

        getLocationOne()
    }, [])
    const getLocationOne = async () => {


        const response = await API.get(LOCATION1_URL);
        if (response.status === 200) {

            setLocationOne(response.data)
            setSelectedLocationTwo('');
            setSelectedLocationThree('');
            setSelectedLocationFour('');
        }
    }


    const [selectedLocationOne, setSelectedLocationOne] = useState('');


    const [locationTwo, setLocationTwo] = useState([]);
    useEffect(() => {
        if (selectedLocationOne)
            getLocationOneTwo();
        else {
            setSelectedLocationTwo('');
            setSelectedLocationThree('');
            setSelectedLocationFour('');
        }
    }, [selectedLocationOne])

    const getLocationOneTwo = async () => {


        const response = await API.get(LOCATION_TIER1_URL(selectedLocationOne));
        if (response.status === 200) {

            setLocationTwo(response.data)
            setSelectedLocationTwo('');
            setSelectedLocationThree('');
            setSelectedLocationFour('');
        }
    }
    const [selectedLocationTwo, setSelectedLocationTwo] = useState('');

    const [locationThree, setLocationThree] = useState([]);
    useEffect(() => {
        if (selectedLocationTwo)
            getLocationTwoThree();
        else {

            setSelectedLocationThree('');
            setSelectedLocationFour('');
        }
    }, [selectedLocationTwo])

    const getLocationTwoThree = async () => {


        const response = await API.get(TIER1_TIER2_URL(selectedLocationTwo));
        if (response.status === 200) {

            setLocationThree(response.data)
            setSelectedLocationThree('');
            setSelectedLocationFour('');
        }
    }
    const [selectedLocationThree, setSelectedLocationThree] = useState('');

    const [locationFour, setLocationFour] = useState([]);
    useEffect(() => {
        if (selectedLocationThree)
            getLocationThreeFour();
        else {

            setSelectedLocationFour('');
        }
    }, [selectedLocationThree])

    const getLocationThreeFour = async () => {


        const response = await API.get(TIER2_TIER3_URL(selectedLocationThree));
        if (response.status === 200) {

            setLocationFour(response.data)
            setSelectedLocationFour('');
        }
    }

    const [selectedLocationFour, setSelectedLocationFour] = useState('');

    const [title, setTitles] = useState({ tier1: 'Tier I', tier2: 'Tier II', tier3: 'Tier III', tier4: 'Tier IV' });
    useEffect(() => {

        getLocationConfigs();
    }, [])

    const getLocationConfigs = async () => {
        const response = await API.get(DYNAMIC_TITLES_URL);

        if (response.status === 200) {
            const titles = response.data;
            // setTitleData(titles)
            const locationsObject = titles.reduce((obj, item) => {
                obj[item.title] = item.altTitle;

                return obj;
            }, {});
            setTitles({ tier1: locationsObject.LocationOne, tier2: locationsObject.LocationTwo, tier3: locationsObject.LocationThree, tier4: locationsObject.LocationFour })
        }


    }

    useEffect(() => {
        props.handleFilter(selectedLocationOne, selectedLocationTwo, selectedLocationThree, selectedLocationFour)
    }, [selectedLocationOne, selectedLocationTwo, selectedLocationThree, selectedLocationFour])
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className='mb-4'>
                        <label className='mb-2 font-sm'> {title.tier1}</label>
                        <select className='form-select' onChange={(e) => setSelectedLocationOne(e.target.value)} >
                            <option value={''}>{props.disableAll ? 'Choose' : 'All'} {title.tier1}</option>

                            {
                                locationOne.map(i => {
                                    return <option key={i.id} value={i.id}>{i.name}</option>

                                })
                            }

                        </select>
                    </div>
                </div>

                {selectedLocationOne && selectedLocationOne !== 'tier1-all' && <div className="col-12">
                    <div className='mb-4'>
                        <label className='mb-2 font-sm'> {title.tier2}</label>
                        <select
                            className='form-select'
                            onChange={(e) => setSelectedLocationTwo(e.target.value)}

                        >
                            <option value={''}>{props.disableAll ? 'Choose' : 'All'} {title.tier2}</option>

                            {
                                locationTwo.map(i => {
                                    return <option key={i.id} value={i.id}>{i.name}</option>

                                })
                            }
                        </select>
                    </div>
                </div>}

                {selectedLocationTwo && selectedLocationTwo !== 'tier2-all' && <div className="col-12">
                    <div className='mb-4'>
                        <label className='mb-2 font-sm'> {title.tier3}</label>
                        <select
                            className='form-select'
                            onChange={(e) => setSelectedLocationThree(e.target.value)}

                        >
                            <option value={''}>{props.disableAll ? 'Choose' : 'All'} {title.tier3}</option>

                            {
                                locationThree.map(i => {
                                    return <option key={i.id} value={i.id}>{i.name}</option>

                                })
                            }
                        </select>
                    </div>
                </div>}

                {selectedLocationThree && selectedLocationThree !== 'tier3-all' && <div className="col-12">
                    <div className='mb-4'>
                        <label className='mb-2 font-sm'> {title.tier4}</label>
                        <select
                            className='form-select'
                            onChange={(e) => setSelectedLocationFour(e.target.value)}

                        >
                            <option value={''}>{props.disableAll ? 'Choose' : 'All'} {title.tier4}</option>

                            {
                                locationFour.map(i => {
                                    return <option key={i.id} value={i.id}>{i.name}</option>

                                })
                            }
                        </select>
                    </div>
                </div>
                }

            </div>
        </>
    )
}

export default FilterLocation;